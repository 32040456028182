import {createReducer} from '@reduxjs/toolkit'
import {getStaticMapUrl as getStaticMapUrlAction} from '../actions/static-map'
import {State} from '../types'

const defaultState = null

export const staticMapUrl = createReducer(defaultState, builder => {
  builder.addCase(getStaticMapUrlAction.fulfilled, (_state, action) => action.payload)
})

export const getStaticMapUrl = (state: State) => state.staticMapUrl
