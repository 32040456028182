import {getDownloadUrlPublic} from '@wix/ambassador-events-v2-ticket/http'
import {GetDownloadUrlPublicRequest, GetDownloadUrlPublicResponse} from '@wix/ambassador-events-v2-ticket/types'
import {getSeatingSummary} from '@wix/ambassador-seating-v1-seating-plan/http'
import {getSeatingReservationsSummary} from '@wix/ambassador-seating-v1-seating-reservation/http'
import {GetSeatingReservationsSummaryResponse} from '@wix/ambassador-seating-v1-seating-reservation/types'
import {getActivityStats} from '@wix/ambassador-social-groups-v1-activity-stats/http'
import {GetActivityStatsResponse} from '@wix/ambassador-social-groups-v1-activity-stats/types'
import {listGroupsByEventId} from '@wix/ambassador-social-groups-v1-group-event/http'
import {getGroup} from '@wix/ambassador-social-groups-v2-group/http'
import {GetGroupResponse} from '@wix/ambassador-social-groups-v2-group/types'
import {PageData, PlanList, TrimmedMember} from '@wix/events-types'
import {addQueryParams} from '@wix/panda-js-utils'
import {
  ExperimentNames,
  WIX_EVENTS_FQDN,
  defaultLocale,
  draftEventPreviewUrlQueryParamName,
} from '@wix/wix-events-commons-statics'
import {ControllerFlowAPI, ControllerParams} from '@wix/yoshi-flow-editor'
import {GET_SITE_SETTINGS, UPDATE_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../../../commons/types/state'
import {Api as BaseApi} from '../../../../commons/utils/api'
import {GET_CHECKOUT_OPTIONS} from '../actions/checkout-options'
import {GET_EVENT, GET_MEMBERS} from '../actions/event'
import {GET_CONNECTED_GROUP, GET_GROUP_ACTIVITY} from '../actions/groups'
import {FETCH_CURRENT_MEMBER, PROMPT_LOGIN} from '../actions/members'
import {GET_ORDER, PLACE_ORDER, UPDATE_ORDER} from '../actions/placed-order'
import {GET_POLICIES} from '../actions/policies'
import {RESERVE_TICKETS} from '../actions/reservation'
import {DELETE_RSVP, GET_MEMBER_RSVP, SEND_RSVP, UPDATE_RSVP, UPDATE_RSVP_STATUS} from '../actions/rsvp'
import {RegFormData} from '../types'
import {extractFormData} from './api-data-mapper'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [GET_EVENT.NAME]: this.getEvent,
      [GET_MEMBERS.NAME]: this.getMembers,
      [GET_CHECKOUT_OPTIONS.NAME]: this.getCheckoutOptions,
      [RESERVE_TICKETS.NAME]: this.makeReservation,
      [GET_MEMBER_RSVP.NAME]: this.getMemberRsvp,
      [SEND_RSVP.NAME]: this.createRsvp,
      [DELETE_RSVP.NAME]: this.deleteRsvp,
      [UPDATE_RSVP.NAME]: this.updateRsvp,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [UPDATE_ORDER.NAME]: this.updateOrder,
      [PLACE_ORDER.NAME]: this.placeOrder,
      [GET_ORDER.NAME]: this.getOrder,
      [FETCH_CURRENT_MEMBER.NAME]: this.getCurrentMember,
      [UPDATE_RSVP_STATUS.NAME]: this.updateRsvpStatus,
      [GET_SITE_SETTINGS.NAME]: this.getSiteSettings,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [GET_POLICIES.NAME]: this.getPolicies,
      [GET_CONNECTED_GROUP.NAME]: this.getGroup,
      [GET_GROUP_ACTIVITY.NAME]: this.getGroupActivity,
    }
  }

  getData = ({
    slug,
    responsive = false,
    schedulePageInstalled,
    draftPreviewToken,
    flowAPI,
    newClassicDemoEvents,
  }: GetDataArgs) => {
    const encodedSlug = encodeURIComponent(slug)
    if (encodedSlug.length > 130) {
      flowAPI.reportError(slug)
      return {} as any
    }
    const url = addQueryParams(`/html/page-data/${encodedSlug}`, {
      compId: this.compId,
      locale: this.language,
      viewMode: this.viewMode,
      responsive: String(responsive),
      schedule: String(schedulePageInstalled),
      regional: this.locale,
      [draftEventPreviewUrlQueryParamName]: draftPreviewToken,
      tz: this.tz,
      defaultAlphabetical: flowAPI.experiments.enabled(ExperimentNames.SausageIntegration),
      newClassicDemoEvents: newClassicDemoEvents ? true : undefined,
    })

    return this.api.get<PageData>(url)
  }

  getOrder = async (
    eventId: string,
    orderNumber: string,
    token: string = '',
    locale: string = defaultLocale,
  ): Promise<wix.events.ticketing.Order> => {
    if (token) {
      return this.api.get(`/web/orders/by-token?token=${token}&locale=${locale}`)
    } else {
      return this.api.get(`/web/events/${eventId}/orders/${orderNumber}?locale=${locale}`)
    }
  }

  placeOrder = ({
    eventId,
    buyer,
    guests,
    couponCode,
    memberId,
    planOrderId,
    benefitId,
    locale,
    paymentRedirectUrl,
  }: PlaceOrderArgs): Promise<{order: wix.events.ticketing.Order}> =>
    this.writeApi.post(`/web/events/${eventId}/checkout`, {
      [guests ? 'buyer' : 'data']: extractFormData(buyer),
      guests,
      reservationId: buyer.reservation,
      memberId,
      couponCode,
      planOrderId,
      benefitId,
      locale,
      paymentRedirectUrl,
    })

  updateOrder = ({
    eventId,
    orderNumber,
    buyer,
    guests,
    locale,
    couponCode,
    paidPlanBenefit,
  }: UpdateOrderParams): Promise<wix.events.ticketing.UpdateOrderResponse> =>
    this.writeApi.put(`/web/events/${eventId}/checkout/${orderNumber}`, {
      buyer,
      guests: guests?.length ? guests : undefined,
      locale,
      couponCode,
      paidPlanBenefit,
    })

  getEvent = (eventId: string) => this.api.get(`/web/events/${eventId}/viewer`)

  getMembers = (eventId: string): Promise<{members: Member[]; totalGuests: TotalEventGuests}> =>
    this.api.get(`/web/events/${eventId}/members`)

  getInvoice = (
    eventId: string,
    reservationId: string,
    couponCode?: string,
    benefitId?: string,
    planOrderId?: string,
  ): Promise<wix.events.ticketing.GetInvoiceResponse> => {
    return this.api.put(`/web/events/${eventId}/invoice`, {reservationId, couponCode, benefitId, planOrderId})
  }

  getCheckoutOptions = async (): Promise<wix.events.ticketing.GetCheckoutOptionsResponse> => {
    try {
      const response = await this.api.get('/web/checkout-options')
      return response
    } catch (e) {
      return {
        premiumServices: true,
        paymentMethodConfigured: true,
        acceptCoupons: true,
      }
    }
  }

  makeReservation = (
    eventId: string,
    ticketQuantities: wix.events.ticketing.TicketReservationQuantity[],
  ): Promise<{id: string; expires: string}> => {
    return this.writeApi.post(`/web/events/${eventId}/reservations`, {
      ticketQuantities,
      userDateTime: new Date().toISOString(),
    })
  }

  cancelReservation = (eventId: string, reservationId: string): Promise<{}> =>
    this.writeApi.delete(`/web/events/${eventId}/reservations/${reservationId}`)

  getMemberRsvp = async (eventId: string, memberId: string): Promise<wix.events.rsvp.Rsvp> => {
    return this.api
      .get<{rsvp: wix.events.rsvp.Rsvp}>(`/web/events/${eventId}/member-rsvp?memberId=${memberId}`)
      .then(response => response.rsvp)
  }

  getCurrentMember = (): Promise<{
    lastName: string
    firstName: string
    loginEmail: string
    id: string
  }> => this.api.get<{member: TrimmedMember}>(`/web/members/current`).then(response => response.member)

  getEventsMembersPage = () =>
    this.api.get<{page: {pageId: string}}>('/web/events-members-page').then(response => response.page)

  createRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    memberId?: string,
  ): Promise<wix.events.rsvp.CreateRsvpResponse> => {
    return this.writeApi.post(`/web/events/${eventId}/v2/rsvp`, {memberId, status, data})
  }

  deleteRsvp = (eventId: string, rsvpId: string) =>
    this.writeApi.delete(`/web/events/${eventId}/rsvp/${rsvpId}`).then(() => ({eventId}))

  updateRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    rsvpId: string,
  ): Promise<wix.events.rsvp.UpdateRsvpResponse> => {
    return this.writeApi.put(`/web/events/${eventId}/rsvp/${rsvpId}/form`, {data, status})
  }

  updateRsvpStatus = (
    eventId: string,
    rsvpId: string,
    status: wix.events.rsvp.RsvpStatus,
  ): Promise<{eventId: string}> =>
    this.writeApi.put(`/web/events/${eventId}/rsvp/${rsvpId}/status`, {status}).then(() => ({eventId}))

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  getPlanList = (eventId: string): Promise<{plans: PlanList}> => this.api.get(`/web/plans?eventId=${eventId}`)

  getSiteSettings = () => this.writeApi.get('/web/site-settings')

  updateSiteSettings = (settings: SiteSettingsSettings) => this.writeApi.put('/web/site-settings', {settings})

  getPolicies = (eventId: string) => this.api.get(`/web/events/${eventId}/policies`)

  getGroup = async (eventId: string): Promise<GetGroupResponse> => {
    const {
      data: {groupIds},
    } = await this.httpClient.request(listGroupsByEventId({eventId}))

    if (groupIds?.length) {
      return (await this.httpClient.request(getGroup({groupId: groupIds[0]}))).data
    }
  }

  getGroupActivity = async (groupId: string): Promise<GetActivityStatsResponse> =>
    (await this.httpClient.request(getActivityStats({groupId}))).data

  describeSeatingPlan = async (eventId: string, newApi = false): Promise<GetSeatingReservationsSummaryResponse> => {
    if (newApi) {
      return this.httpClient
        .request(
          getSeatingReservationsSummary({
            filter: {
              externalId: {
                $eq: `${WIX_EVENTS_FQDN}${eventId}`,
              },
            },
          }),
        )
        .then(r => r.data)
    } else {
      const response = await this.httpClient
        .request(
          getSeatingSummary({
            filter: {
              externalId: {
                $eq: `${WIX_EVENTS_FQDN}${eventId}`,
              },
            },
          }),
        )
        .then(r => r.data)

      return {plan: response.plan, seatingReservationsSummary: response.seatingReservationSummary}
    }
  }

  getPapyrusTicketsDownloadUrl = async (args: GetDownloadUrlPublicRequest): Promise<GetDownloadUrlPublicResponse> =>
    this.httpClient.request(getDownloadUrlPublic(args)).then(response => response.data)

  getStaticMapUrl = async (eventId: string, language: string): Promise<string> => {
    return this.api.get<string>(addQueryParams(`/html/static-map/${eventId}`, {languageOverride: language}))
  }
}

interface PlaceOrderArgs {
  eventId: string
  buyer: RegFormData
  guests: RegFormData[]
  couponCode: string
  memberId: string
  planOrderId: string
  benefitId: string
  locale: string
  paymentRedirectUrl?: string
}

interface GetDataArgs {
  slug: string
  responsive: boolean
  schedulePageInstalled: boolean
  draftPreviewToken: string
  flowAPI: ControllerFlowAPI
  newClassicDemoEvents?: boolean
}

interface UpdateOrderParams {
  eventId: string
  orderNumber: string
  buyer: RegFormData
  guests: RegFormData[]
  locale: string
  couponCode: string
  paidPlanBenefit?: {benefitId: string; planOrderId: string}
}
